import { extendTheme } from "@chakra-ui/react";
import { theme as proTheme } from "./pro/index.ts";

import * as foundations from "./foundations/index.js";
import * as components from "./components/index.js";

export default extendTheme(proTheme, {
  ...foundations,
  colors: {
    brand: foundations.colors.purple,
    ...foundations.colors,
  },
  components: {
    ...components,
  },
});
