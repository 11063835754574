// Brand

// const purple = {
//   50: "#f2e9ff",
//   100: "#d3c3f2",
//   200: "#b49ce4",
//   300: "#9675d7",
//   400: "#784ecb",
//   500: "#5f34b1",
//   600: "#4a298b",
//   700: "#351c65",
//   800: "#20103e",
//   900: "#0d041b",
// };

// From Stripe
// They have a 150, but chakra doesn't
// So we have to ignore it, or shift lighter/darker

/*
--hue-purple50: #f9f7ff;
--hue-purple100: #f2ebff;
--hue-purple150: #dfd3fc;
--hue-purple200: #d1befe;
--hue-purple300: #b49cfc;
--hue-purple400: #8d7ffa;
--hue-purple500: #625afa;
--hue-purple600: #513dd9;
--hue-purple700: #3f32a1;
--hue-purple800: #302476;
--hue-purple900: #14134e;
*/

const purple = {
  50: "#f9f7ff",
  100: "#f2ebff",
  150: "#dfd3fc",
  200: "#d1befe",
  300: "#b49cfc",
  400: "#8d7ffa",
  500: "#625afa",
  600: "#513dd9",
  700: "#3f32a1",
  800: "#302476",
  900: "#14134e",
};

/*
--hue-orange50: #fef9da;
--hue-orange100: #fcedb9;
--hue-orange150: #fcd579;
--hue-orange200: #fcbd3a;
--hue-orange300: #ff8f0e;
--hue-orange400: #ed6704;
--hue-orange500: #c84801;
--hue-orange600: #a82c00;
--hue-orange700: #842106;
--hue-orange800: #5f1a05;
--hue-orange900: #331302;
*/

const orange = {
  50: "#fef9da",
  100: "#fcedb9",
  // Trend darker
  // 150: "#fcd579",
  // 200: "#fcbd3a",
  // 300: "#ff8f0e",
  // 400: "#ed6704",
  // 500: "#c84801",
  // 600: "#a82c00",
  // 700: "#842106",
  // 800: "#5f1a05",
  // 900: "#331302",
  // Trend lighter
  200: "#fcd579",
  300: "#fcbd3a",
  400: "#ff8f0e",
  500: "#ed6704",
  600: "#c84801",
  700: "#a82c00",
  800: "#842106",
  900: "#5f1a05",
  950: "#331302",
};

/*
--hue-red50: #fff5fa;
--hue-red100: #ffe7f2;
--hue-red150: #ffccdf;
--hue-red200: #ffb1cd;
--hue-red300: #fe87a1;
--hue-red400: #fc526a;
--hue-red500: #df1b41;
--hue-red600: #b3093c;
--hue-red700: #890d37;
--hue-red800: #68052b;
--hue-red900: #3e021a;
*/

const red = {
  50: "#fff5fa",
  100: "#ffe7f2",
  // Trend darker
  150: "#ffccdf",
  200: "#ffb1cd",
  300: "#fe87a1",
  400: "#fc526a",
  500: "#df1b41",
  600: "#b3093c",
  700: "#890d37",
  800: "#68052b",
  900: "#3e021a",
  // Trend lighter
  // 200: "#ffccdf",
  // 300: "#ffb1cd",
  // 400: "#fe87a1",
  // 500: "#fc526a",
  // 600: "#df1b41",
  // 700: "#b3093c",
  // 800: "#890d37",
  // 900: "#68052b",
  // 950: "#3e021a",
};

/*
--hue-green50: #ecfed7;
--hue-green100: #d7f7c2;
--hue-green150: #a6eb84;
--hue-green200: #76df47;
--hue-green300: #48c404;
--hue-green400: #3fa40d;
--hue-green500: #228403;
--hue-green600: #006908;
--hue-green700: #0b5019;
--hue-green800: #043b15;
--hue-green900: #02220d;
*/

const green = {
  50: "#ecfed7",
  100: "#d7f7c2",
  // Trend darker
  // 150: "#a6eb84",
  // 200: "#76df47",
  // 300: "#48c404",
  // 400: "#3fa40d",
  // 500: "#228403",
  // 600: "#006908",
  // 700: "#0b5019",
  // 800: "#043b15",
  // 900: "#02220d",
  // Trend lighter
  200: "#a6eb84",
  300: "#76df47",
  400: "#48c404",
  500: "#3fa40d",
  600: "#228403",
  700: "#006908",
  800: "#0b5019",
  900: "#043b15",
  950: "#02220d",
};

/*
--hue-blue50: #ddfffe;
--hue-blue100: #cff5f6;
--hue-blue150: #a2e5ef;
--hue-blue200: #75d5e8;
--hue-blue300: #06b9ef;
--hue-blue400: #0096eb;
--hue-blue500: #0570de;
--hue-blue600: #0055bc;
--hue-blue700: #04438c;
--hue-blue800: #003262;
--hue-blue900: #011c3a;
*/

const blue = {
  50: "#ddfffe",
  100: "#cff5f6",
  // Trend darker
  150: "#a2e5ef",
  200: "#75d5e8",
  300: "#06b9ef",
  400: "#0096eb",
  500: "#0570de",
  600: "#0055bc",
  700: "#04438c",
  800: "#003262",
  900: "#011c3a",
  // Trend lighter
  // 200: "#a2e5ef",
  // 300: "#75d5e8",
  // 400: "#06b9ef",
  // 500: "#0096eb",
  // 600: "#0570de",
  // 700: "#0055bc",
  // 800: "#04438c",
  // 900: "#003262",
  // 950: "#011c3a",
};

/*
--hue-gray0: #ffffff;
--hue-gray50: #f6f8fa;
--hue-gray100: #ebeef1;
--hue-gray150: #d5dbe1;
--hue-gray200: #c0c8d2;
--hue-gray300: #a3acba;
--hue-gray400: #87909f;
--hue-gray500: #687385;
--hue-gray600: #545969;
--hue-gray700: #414552;
--hue-gray800: #30313d;
--hue-gray900: #1a1b25;
--hue-gray950: #10111a;
*/

const gray = {
  0: "#ffffff",
  50: "#f6f8fa",
  100: "#ebeef1",
  150: "#d5dbe1",
  200: "#c0c8d2",
  300: "#a3acba",
  400: "#87909f",
  500: "#687385",
  600: "#545969",
  700: "#414552",
  800: "#30313d",
  900: "#1a1b25",
  950: "#10111a",
};

export default {
  purple,
  red,
  orange,
  green,
  blue,
  gray,
};
